.is-404-title {
  font-size: 20rem;
  @media screen and (max-width: 640px) {
    font-size: 10rem; }

  @media screen and (max-width: 400px) {
    font-size: 8rem; }

  @media screen and (max-height: 480px) {
    font-size: 9rem; }

  @media screen and (max-height: 480px) {
    font-size: 9rem; }

  @media screen and (max-height: 400px) {
    font-size: 6rem; }

  @media screen and (max-height: 350px) {
    font-size: 4rem; } }


.is-404-content {
  font-size: 1.25rem;
  @media screen and (max-width: 480px), (max-height: 480px) {
    font-size: 1rem; } }
