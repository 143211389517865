.navbar {
  transition: padding .2s;

  background-color: #f7f0e8;
  font-weight: bold; }

@media screen and (min-width: 1024px) {
  .navbar-center {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: stretch;

    .navbar-link,
    .navbar-item {
      align-items: center;
      display: flex;
      flex-basis: auto;
      flex-grow: 1;
      flex-shrink: 0;
      justify-content: center; }

    .navbar-dropdown {
      .navbar-item {
        justify-content: left; } } } }

@media screen and (max-width: 1023px) {
  .navbar {
    padding: 0;
    border-bottom: 1px solid $border;

    &.andalous-taste {
      &.has-shadow {
        box-shadow: none; } }

    .navbar-item {
      img {
        max-height: 2.5rem; } }

    .navbar-burger {
      background: transparent;
      border: 0;
      outline: 0;

      &:hover {
        background-color: $burger-hover; }

      span {
        height: 4px;
        left: calc(50% - 13px);
        width: 26px;

        &:nth-child(1) {
          top: calc(50% - 10px); }

        &:nth-child(2) {
          top: calc(50% - 2px); }

        &:nth-child(3) {
          top: calc(50% + 6px); } }

      &.is-active {
        span {
          &:nth-child(1) {
            transform: translateY(7.7px) rotate(45deg); }

          &:nth-child(3) {
            transform: translateY(-7.7px) rotate(-45deg); } } } } }

  .navbar-menu {
    box-shadow: none;
    display: block;
    height: calc(100vh - #{$navbar-height});
    position: absolute;
    right: -100%;
    top: $navbar-height;
    transition: right .3s;
    width: 100%;

    background-color: #f7f0e8;

    &.is-active {
      right: 0; } } }
