// Bulma variables config
$body-size: 16px;
$family-sans-serif: 'Source Serif Pro', sans-serif;
$navbar-item-img-max-height: 3rem;
$navbar-box-shadow-color: #dbdbdb;
$navbar-height: 4rem;

$danger: #d1806f;
$warning: #efa23b;
$primary: #efa23b;
