.gallery-container {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  width: 100%;

  @media screen and (max-height: 470px) {
    overflow: auto; } }


.is-fullheight {
  .hero-body {
    &.gallery-type {
      align-items: stretch;

      @media screen and (max-height: 470px) {
        padding: 0; } } } }


.gallery-tracker {
  display: flex;
  transition: transform .5s ease 0s;
  width: 100%;

  @media screen and (max-width: 768px) and (orientation: portrait) {
    flex-direction: column;
    transform: none !important; } }


.gallery-item {
  display: flex;
  flex-shrink: 0;
  width: 100%;

  .card {
    display: flex;
    flex-direction: column;
    width: 100%;

    .gallery-image-bearer {
      background-position: center;
      background-size: cover;
      flex-grow: 1;

      @media screen and (max-width: 768px) and (orientation: portrait) {
        padding-bottom: 56.25%; } }


    .card-content {
      @media screen and (max-width: 480px), (max-height: 480px) {
        padding: 1rem; }


      .title {
        @media screen and (max-height: 470px) {
          margin: 0; } } } }

  @media screen and (max-width: 768px) {
    padding: .75rem 0; }

  @media screen and (max-height: 470px) {
    padding: 0 .75rem; } }


.gallery-control {
  cursor: pointer;
  padding: 1rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;

  @media screen and (max-width: 768px), (max-height: 470px) {
    display: none; }


  &.left-control {
    left: 0; }

  &.right-control {
    right: 0; } }

.gallery-description {
  @media screen and (max-height: 480px), (max-width: 600px) {
    display: none; } }

@media screen and (max-width: 768px) {
  .gallery-level {
    display: flex;

    .level-left {
      + .level-right {
        margin-top: 0; } } } }

.gallery-level {
  @media screen and (max-width: 567px) {
    display: block;

    .level-left {
      + .level-right {
        margin-top: 1.4rem; } } }

  @media screen and (max-width: 600px), (max-height: 480px) {
    .level-left {
      .title {
        font-size: 1.4rem; } }

    .level-right {
      .button {
        font-size: 1rem; } } }

  @media screen and (max-height: 480px) {
    &.level {
      margin-bottom: .75rem; } }

  @media screen and (max-width: 320px) {
    .level-left {
      .title {
        text-align: center; } }

    .level-right {
      text-align: center; } } }
