.is-announcement {
  &.message {
    margin-bottom: 0;
    width: 100%;

    .message-header {
      justify-content: center; }

    .message-body {
      padding: .5em 1.5em; } }

  .is-size-6 {
    line-height: 1.2; } }
