.hero-image {
  max-width: 500px;
  width: 100%;

  @media screen and (max-height: 480px) {
    max-width: 220px; } }


.home-buttons {
  margin-top: 3rem;

  .button {
    width: 147px;

    @media screen and (max-width: 1023px) {
      font-size: 1rem;
      width: 128px; } } }


.home-page-body {
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  @media (max-width: $desktop) {
    height: calc(100vh - 5rem); }

  @media (max-width: 768px) {
    height: calc(100vh - 4rem); }

  @media (max-width: 420px) {
    height: calc(100vh - 1rem); }

  > .container {
    z-index: 2; }

  &::after {
    background-color: $white;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: .35;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1; } }

.hero-foot {
  padding: .5rem .25rem;
  position: relative;

  &::after {
    background-color: #cfcfcf;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0; } }

.home-page__container {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.home-page__footer {
  color: $text;
  z-index: 2;
  max-width: 50rem;
  padding: 0 1rem;
  border-radius: 0.25rem;

  background-color: rgba(255, 255, 255, 0.7); }
