// Bulma rule-level overrides
html {
  &.has-navbar-fixed-top {
    padding-top: 6rem;

    @media screen and (max-width: 1023px) {
      padding-top: 4rem; } } }


body {
  overflow: hidden;
  position: relative; }

.hero {
  &.is-fullheight-with-navbar {
    min-height: calc(100vh - 6rem);

    @media screen and (max-width: 1023px) {
      min-height: calc(100vh - 4rem); }


    .hero-body {
      &.align-to-start {
        align-items: flex-start; } } } }


.default-responsive-cards {
  @media screen and (max-width: 768px) {
    display: flex; }

  @media screen and (max-width: 650px) {
    display: block; } }

// Mixins
@mixin helper-class-generator($selector, $rule, $value) {
  #{$selector} {
    #{$rule}: $value; } }

// Custom helper classes
@include helper-class-generator('.has-bottom-border-gray', border-bottom, 1px solid $border-gray);
@include helper-class-generator('.has-light-bottom-margin', margin-bottom, .5rem);
@include helper-class-generator('.has-no-bottom-margin', margin-bottom, 0 !important);
@include helper-class-generator('.has-light-vertical-padding', padding, .5rem 0);
@include helper-class-generator('.has-medium-vertical-padding', padding, .75rem 0);
@include helper-class-generator('.has-medium-bottom-margin', margin-bottom, 1rem);
@include helper-class-generator('.has-tiny-bottom-margin', margin-bottom, 4px);
@include helper-class-generator('.has-very-light-left-margin', margin-left, .25rem);
@include helper-class-generator('.has-very-light-right-margin', margin-right, .25rem);
@include helper-class-generator('.has-medium-right-margin', margin-right, 1rem);
@include helper-class-generator('.has-light-top-margin', margin-top, .5rem);
@include helper-class-generator('.it-is-fullwidth', width, 100%);
@include helper-class-generator('.has-line-breaks', white-space, pre-wrap);
@include helper-class-generator('.is-flexwrapless', flex-wrap, nowrap);
@include helper-class-generator('.is-flexwrap', flex-wrap, wrap);
@include helper-class-generator('.has-columndirection', flex-direction, column);
@include helper-class-generator('.is-flexible', flex, 1);
@include helper-class-generator('.has-gallery-close-padding', padding, .5rem 1rem);
@include helper-class-generator('.has-modified-hero-padding', padding, 0 1.5rem);
@include helper-class-generator('.translated-slightly-upwards', transform, translateY(-8px));

// Google reCaptcha mobile style fix
@media screen and (max-height: 575px) {
  #g-recaptcha {
    max-width: 270px;
    transform: scale(.89);
    -webkit-transform: scale(.89);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0; } }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type=number] {
  -moz-appearance: textfield; }
